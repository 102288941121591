/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif!important;
    background-color: #eee;
    font-size: 0px!important;
}

table {
    width: 100%;
}

.mat-table thead {
    background-color: #f0f0f0!important;
}

.nav-content {
    position: relative;
    /* Add relative positioning to the nav-content */
    height: 100%;
}


/* textarea styling starts */

.textarea-bg {
    background-color: white!important;
    font-size: 14px!important;
}

p {
    font-size: 14px!important;
}

.dateRangePickStyle {
    font-size: 14px!important;
}


/* textarea styling ends */


/* chip starts */

.example-chip-list {
    width: 100%;
}

.matChipClass {
    font-size: 14px;
}

.mat-chip {
    font-size: 14px;
    font-weight: 400!important;
}

.mat-standard-chip .mat-chip-remove .mat-icon {
    width: 10px;
    height: 0px;
    font-size: 10px;
}

.disableOnClick {
    pointer-events: none;
    cursor: not-allowed;
}

.mat-flat-button {
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    line-height: 0px!important;
}

.btnGenerate {
    padding-left: 0px!important;
}

.btnExportExcel {
    height: 28px!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 0px!important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #142241;
}


/* chip ends */

a {
    color: #142241!important;
    text-decoration: none;
}

.mat-tab-body-content {
    overflow: visible!important;
}

mat-toolbar.sticky {
    position: -webkit-sticky;
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 100;
}

label.col-form-label {
    color: #142241;
    font-size: 15px;
    font-weight: 420;
    padding-top: 1%;
}

input {
    font-size: 14px!important;
}

.mat-form-field-infix {
    font-size: 14px!important;
}

.mat-select-panel .mat-option {
    font-size: 14px!important;
    line-height: 3em;
    height: 3em;
}

.base-form {
    margin-top: 30px;
    background-color: rgb(240, 240, 240);
}

.mat-checkbox-label {
    color: #142241;
    font-size: 14px;
    font-weight: 400;
}

.mat-select {
    font-size: 14px;
}

.select-input {
    background: rgb(240, 240, 240);
    height: 30px;
}

.mat-simple-snackbar {
    font-size: 1.2em;
    color: white;
}

.snackbar-error {
    background-color: #c93838d1!important;
}

.snackbar-success {
    background-color: #1f931f7d!important;
}

.snackbar-info {
    background-color: #3b7db7c9!important;
}

.span-class {
    color: #ff0000;
}

.validation-class {
    color: #ff0000;
    font-size: 14px;
}

.mat-list-base .mat-list-item .mat-list-item-content {
    margin-top: -10px;
}

.nav-sidenav {
    background-color: #1f1f1f!important
}

.nav-sidenav.expanded {
    background-color: #1f1f1f!important;
}

h3,
.h3 {
    margin-top: 0px!important;
    font-size: 19px!important;
    font-weight: 450!important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px 4px 0 0!important;
    padding: .0em .0em 0 .0em!important;
}

button.btn.btn-success.afu-upload-btn.ng-star-inserted {
    background-color: #142241!important;
}

button.btn.btn-info.btn-sm.afu-reset-btn.ng-star-inserted {
    background-color: #fff!important;
    color: black;
}

label.btn.btn-primary.btn-sm.afu-select-btn.ng-star-inserted {
    background-color: #142241!important;
}


/* mat table styling starts */

.mat-table {
    border-radius: 5px;
    overflow: hidden;
}

.mat-row:nth-child(even) {
    background-color: rgb(250, 250, 250);
}

tr.mat-row {
    height: 35px!important;
}

.mat-header-cell {
    font-weight: bold;
}

.mat-cell,
.mat-footer-cell {
    font-size: 14px!important;
}

.mat-header-cell {
    font-size: 14px!important;
}

.mat-row:hover {
    background-color: rgb(240, 240, 240);
}

.filter-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -25px;
}

.mat-column-select {
    width: 50px;
    padding-right: 24px;
}

.mat-checkbox-inner-container {
    height: 14px;
    width: 14px;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.overlay {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.286);
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
}


/* mat table styling ends */

@media (max-width: 1199.98px) {
    .g-sidenav-show.rtl .sidenav {
        transform: translateX(17.125rem)
    }
    .g-sidenav-show:not(.rtl) .sidenav {
        transform: translateX(-17.125rem);
    }
    .g-sidenav-show .sidenav.fixed-start+.main-content {
        margin-left: 0!important
    }
    .g-sidenav-show.g-sidenav-pinned .sidenav {
        transform: translateX(0)
    }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: rgba(0, 0, 0, 0.286)!important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: rgba(0, 0, 0, 0.286)!important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #142241!important;
}

th:last-child,
td:last-child {
    width: 100%;
    /* Second column takes up remaining space */
}

.mat-form-field {
    display: inline!important;
}


/* To make the dialog box for job order, etc scrollable */

.mat-dialog-container {
    height: auto!important;
}